<template>
    <div @click="editMode = true" v-click-outside="save">
        <input class="form-control form-control--mid" v-if="editMode" v-model="value" @keyup.enter="save" @keyup.esc="reset">
        <template v-else>{{value}}</template>
    </div>
</template>

<script>

export default {
    props: {
        rowId: Number,
        columnData: String,
    },

    data() {
        return {
            value: this.columnData,
            editMode: false,
        }
    },

    watch: {
        columnData() {
            this.value = this.columnData
        }
    },

    methods: {
        save() {
            this.editMode = false
            if (this.value != this.columnData) {
                if (this.value.length) {
                    this.$emit('action', {action: 'value-changed', rowId: this.rowId, value: this.value})
                }
                else {
                    this.reset()
                }
            }
        },

        reset() {
            this.editMode = false
            this.value = this.columnData
        }
    }
}
</script>

<style scoped>

</style>